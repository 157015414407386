import { groupSlice } from './slice';

import * as actions from './actions';

export * from './types';

export const groupReducer = groupSlice.reducer;

export { initialState } from './slice';

export const groupActions = {
  ...actions,
};
