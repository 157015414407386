import { configureStore } from '@reduxjs/toolkit';
import { ControllerParams } from '@wix/yoshi-flow-editor';

import { applicationReducer } from './application';
import { groupReducer } from './group';
import { feedReducer } from './feed';

import { IStore } from './types';

export function createStore(config: ControllerParams) {
  const { flowAPI } = config;
  const {
    appParams,
    wixCodeApi: {
      user: { currentUser },
    },
  } = flowAPI.controllerConfig;

  return configureStore<IStore>({
    reducer: {
      application: applicationReducer,
      group: groupReducer,
      feed: feedReducer,
    },
    preloadedState: {
      application: {
        instance: appParams.instance,
        user: {
          id: currentUser.id,
          isLoggedIn: currentUser.loggedIn,
        },
      },
    },
    middleware(getDefaultMiddleware) {
      return getDefaultMiddleware({
        thunk: {
          extraArgument: flowAPI.httpClient,
        },
        serializableCheck: false,
      }) as any;
    },
  });
}
