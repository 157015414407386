import {
  createSettingsParams,
  ISettingsParam,
  SettingsParamType,
} from '@wix/yoshi-flow-editor/tpa-settings';

import { settingsParams as groupSettingsParams } from 'Group/Settings/settingsParams';

export type ISettingsParams = {
  groupId: SettingsParamType.Text;
  layoutSpacing: SettingsParamType.Number;
  showReactions: SettingsParamType.Boolean;
  showComments: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  showReactions: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },

  showComments: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },

  layoutSpacing: groupSettingsParams.layoutSpacing as ISettingsParam,

  groupId: {
    type: SettingsParamType.Text,
    getDefaultValue: () => '',
  },
});
