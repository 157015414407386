import { createAsyncThunk } from '@reduxjs/toolkit';

import { SocialGroupsWeb } from '@wix/ambassador-social-groups-web/http';

import { IStore } from '../types';

export const fetchGroup = createAsyncThunk(
  'group:fetch',
  function (groupId: string, thunkAPI) {
    const { application } = thunkAPI.getState() as IStore;

    const service = SocialGroupsWeb(
      '/_api/social-groups/',
    ).SocialGroupsService()({
      Authorization: application.instance,
    });

    return service.getGroup({ groupId });
  },
);
