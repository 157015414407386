import { feedSlice } from './slice';

export * from './types';

export const feedReducer = feedSlice.reducer;

import * as actions from './actions';

export { initialState } from './slice';

export const feedActions = {
  ...actions,
  ...feedSlice.actions,
};
