import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IApplicationState } from './types';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';

export const initialState: IApplicationState = {
  instance: '',
  user: {
    id: '',
    isLoggedIn: false,
  },
};

interface ILoginPayload {
  instance: string;
  user: IUser;
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    login(state, action: PayloadAction<ILoginPayload>) {
      state.instance = action.payload.instance;
      state.user.id = action.payload.user.id;
      state.user.isLoggedIn = action.payload.user.loggedIn;
    },
  },
});
