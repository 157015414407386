import { applicationSlice } from './slice';

export * from './types';

export const applicationReducer = applicationSlice.reducer;

export { initialState } from './slice';

export const applicationActions = {
  ...applicationSlice.actions,
};
